<template>
  <div class="bg-gray-50">
      <div class="fixed inset-0 flex z-40 lg:hidden" role="dialog" aria-modal="true" v-if="showFilter">

        <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

        <div class="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
          <div class="px-4 flex items-center justify-between">
            <h2 class="text-lg font-medium text-gray-900">Filtrat</h2>
            <button type="button" class="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400" @click="hideFilters">
              <span class="sr-only">Close menu</span>

              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!-- Filters -->
          <form class="mt-4 border-t border-gray-200">
            <h3 class="sr-only">Categories</h3>
            <ul v-show="selectedFilters.length > 0" role="list" class="font-medium text-gray-900 px-2 py-3">
              <li  v-for="filter in selectedFilters">
                <a href="#" class="px-2 py-1 block"  @click.prevent="removeFilter(filter)">
                  {{ filter.name }}
                  <span class="w-4 h-4 hover:bg-red-200 rounded-full cursor-pointer text-red-700 pl-2">
                      x
                    </span>
                </a>
              </li>
            </ul>

            <div class="border-t border-gray-200 px-4 py-6">
              <h3 class="-mx-2 -my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button type="button" class="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500" aria-controls="filter-section-mobile-1" aria-expanded="false">
                <span class="font-medium text-gray-900">
                  Kategoritë
                </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              <div class="pt-6" id="filter-section-mobile-1">
                <div class="space-y-6">
                  <div class="flex items-center" v-for="cat in categories">
                    <input :id="`filter-mobile-category-${cat.id}`" v-model="selectedFilters" :value="cat" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500">
                    <label :for="`filter-mobile-category-${cat.id}`" class="ml-3 min-w-0 flex-1 text-gray-500">
                     {{cat.name}}
                    </label>
                  </div>

                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
      <main class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative z-10 flex items-baseline justify-between pt-24 pb-6 border-b border-gray-200">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900"> Lista e produkteve</h1>

          <div class="flex items-center">
            <div class="relative inline-block text-left">
              <div>
                <button @click="showSort" type="button" class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900" id="menu-button" aria-expanded="false" aria-haspopup="true">
                  {{activeSort.name}}
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>

              <div v-if="showSortList" class="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                  <a v-for="sort in sortByList" :key="sort.name" @click.prevent="sortBy(sort)" href="#" class="font-medium text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                   {{sort.name}}
                  </a>
                </div>
              </div>
            </div>

            <button type="button" class="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden" @click="hideFilters">
              <span class="sr-only">Filters</span>
              <!-- Heroicon name: solid/filter -->
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
            <!-- Filters -->
            <form class="hidden lg:block">
              <h3 class="sr-only">Categories</h3>
              <ul  v-show="selectedFilters.length > 0" role="list" class="text-sm font-medium text-gray-900 space-y-4 pb-6 border-b border-gray-200 bg-white rounded-md px-2 pt-8 shadow-sm">
                <li  v-for="filter in selectedFilters">
                  <a href="#" class="px-2 py-1 border"  @click.prevent="removeFilter(filter)">
                    {{ filter.name }}
                    <span class="w-4 h-4 hover:bg-red-200 rounded-full cursor-pointer text-red-700 pl-2">
                      x
                    </span>
                  </a>
                </li>

              </ul>

              <div class="border-b border-gray-200 py-6">
                <h3 class="-my-3 flow-root">
                  <!-- Expand/collapse section button -->
                  <button type="button" class="py-3 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-1" aria-expanded="false">
                  <span class="text-xl font-bold text-gray-700">
                    Kategoritë
                  </span>
                  </button>
                </h3>
                <!-- Filter section, show/hide based on section state. -->
                <div class="bg-white rounded-md pl-3 px-4 py-3 mt-3 shadow-sm" id="filter-section-1">
                  <div class="space-y-4">
                    <div class="flex items-center" v-for="cat in categories">
                      <input :id="`filter-category-${cat.id}`"  v-model="selectedFilters" :value="cat" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500">
                      <label :for="`filter-category-${cat.id}`" class="ml-3 text-sm text-gray-600">
                       {{cat.name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </form>

            <!-- Product grid -->
            <div class="lg:col-span-3">
              <div class=" rounded-lg h-96 lg:h-full">
                <div class=" mx-auto flex items-center flex-wrap pt-4 pb-12">
                  <product v-for="product in products" :key="product.id" :product="product" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Icon from '@/components/Icon'
import Product from '@/components/Product'
export default {
  name: 'Shop',
  components: {
    Icon,
    Product,
  },
  data: () => ({
    selectedFilters: [],
    showFilter: true,
    showSortList: false,
    activeSort:{
      name:'Rendit'
    },
    sortByList:[
      {
        name:'Emri i produktit: A në Z',
        col:'name',
        sort:'ASC',
      },
      {
        name:'Emri i produktit: Z në A',
        col:'name',
        sort:'DESC',
      },
      {
        name:'Më të rejat ',
        col:'id',
        sort:'DESC',
      },
      {
        name:'Cmimi: Më i ulëti në fillim',
        col:'price',
        sort:'ASC',
      },
      {
        name:'Cmimi: Më i larti në fillim',
        col:'price',
        sort:'DESC',
      },
      {
        name:'Rendit',
        col:'',
        sort:'',
      }
    ],
  }),
  watch:{
    selectedFilters:{
      handler: function(newValue) {
        this.$store.dispatch('products/getProducts', {text: 'load productscat',sortCol:this.activeSort.col,sortBy:this.activeSort.sort,categories:newValue.map(c=>c.id)})
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('products', ['products']),
    ...mapGetters('products', ['categories']),
  },

  methods: {
    removeAll() {
      this.selectedFilters = []
    },
    hideFilters() {
      this.showFilter = !this.showFilter
    },
    sortBy(sort) {
      this.showSortList = !this.showSortList
      this.activeSort = sort
      this.$store.dispatch('products/getProducts', {text: 'load products 2',sortCol:sort.col,sortBy:sort.sort,categories:this.selectedFilters.map(c=>c.id)})
    },
    showSort() {
      this.showSortList = !this.showSortList
    },
    removeFilter(filter) {
      this.selectedFilters = this.selectedFilters.filter(
        (item) => item !== filter
      )
    },
  },

  created() {
    this.$store.dispatch('products/getProducts', {text: 'load products'})
    this.$store.dispatch('products/getCategories', {text: 'load categories'})
    this.$store.dispatch('home/hideBasketAndAccount');
  }
}
</script>

<style></style>

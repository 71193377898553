<template>
  <div class="w-full md:w-1/3 xl:w-1/4 p-6 flex flex-col">
    <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
      <div class="relative">
        <span v-if="product.discount" class="rounded text-white py-1 px-3 text-xs font-bold bg-red-400 absolute z-40 right-2 top-2"> {{ product.discount }}%</span>
        <img class="hover:grow hover:shadow-lg rounded-md" :src="product.photo ? product.photo : require('@/assets/img/thumb.jpg')">
      </div>
      <div class="pt-3 flex items-center justify-between">
        <p class="">{{product.name}}</p>
        <icon @click.stop.prevent="addItem(product)" name="shopping-bag" class="h-6 w-6 fill-current text-gray-500 hover:text-black"/>
      </div>
      <p class="pt-1 text-gray-900">{{$filters.currency(product.sale_price)}}</p>
    </router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/Icon'
export default {
  name: "Product",
  props:{
    product:Object
  },
  components:{
    Icon,
  },
  methods:{
    ...mapActions('cart', ['addItem'])
  }
}
</script>

<style scoped>

</style>
